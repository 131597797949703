.account-page {
    background-color: #08082F;
    padding: 0px 5px 0px 10px;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.account-page-user-info {
    padding: 1rem; 
  }
  
  .account-page-user-info p {
    font-weight: bold;
    margin: 0;
    color: white;
  }
  
  .account-page-user-info span {
    display: inline-block;
    margin-left: 10px;
    color: white;
    text-transform: capitalize;
  }
  
  .account-page-user-info p + p {
    margin-top: 10px;
  }

  .reset-password-link {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .reset-password-link a {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
  }
  
  .reset-password-link a:hover {
    text-decoration: underline;
  }
  
  .reset-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .reset-popup {
    background: #042040;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .reset-popup p{
    color: #fff;
    margin-bottom: 2rem;
  }
  
  .reset-popup-buttons {
    margin-top: 20px;
  }
  
  .reset-popup-buttons button {
    margin: 0 15px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reset-popup-buttons button:first-child {
    background-color: #d9534f;
    color: white;
  }
  
  .reset-popup-buttons button:last-child {
    background-color: #5bc0de;
    color: white;
  }
  
  