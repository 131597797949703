.client-list-container {
  background-color: #08082F; /* Dark blue background */
  /* color: white; */
  padding: 20px;
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  min-height: 100vh;
}

.client-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 clients per row */
    gap: 20px; /* Adjust the gap between client items as needed */
  }
  
  .client-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .client-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff; /* Adjust the background color as needed */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .client-name {
    text-align: center;
  }
  