/* SummaryScreen.css */
.container {
    background-color: #08082F;
    color: white;
    font-family: Arial, sans-serif;
    /* padding: 20px; */
    /* min-height: 100vh; */
  }
  
  .profileName {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .textArea {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
  }
  
  .saveButton {
    background-color: #0059b3; /* Updated background color */
    color: #FFFFFF; /* Adjusted text color for better contrast */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }


.button-settings {
  display: flex;
}

.save-btn {
  flex: 1;
  padding: 10px;
  background-color: #0059b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: "brandFont";
  margin-right: 10px;
}

.save-btn:hover {
  background-color: #0073e6;
}

.restore-btn {
  flex: 1;
  padding: 10px;
  background-color: #0059b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: "brandFont";
}

.restore-btn:hover {
  background-color: #0073e6;
}