.audio-recorder-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* height: 100vh;  */
    text-align: center;
}

select {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
}

.timer {
    font-size: 2em;
    margin: 20px 0;
}

.button-container {
    display: flex;
    justify-content: center;
}

.button-container button {
    background: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button i {
    color: white;
}

button:disabled {
    background: #ccc;
}

button:focus {
    outline: none;
}

.dropdown-item{
    text-align: center;
    font-weight: 900;
}
.dropdown-menu.show {
    height: 200px;
    overflow-y: scroll;
    inset: 0px 0px auto 0px !important;
}
.MentorList_Dropdown {
    height: 300px;
    overflow-y: scroll;
  }

.audio-recorder-btn {
    margin-bottom: 20px !important;
    display: block;
    width: 100%;
    /* padding: 10px; */
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 900;
}