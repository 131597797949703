.signup-container {
    background-color: #08082F; /* Dark blue background */
    color: white;
    padding: 20px;
    /* border-radius: 8px; */
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh; /* This will make sure the container covers the full viewport height */
}

.signup-container .logo {
    color: #FFFFFF; /* Lighter blue for the logo */
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}

input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.sign-up-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.sign-up-button:hover {
    background-color: #0073e6;
}

.links {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.links a {
    color: #FFFFFF; /* Lighter blue for links */
    text-decoration: none;
}

.links a:hover {
    text-decoration: underline;
}


.error {
    color: #ff6666; /* Red color for errors */
    font-size: 0.8em;
    margin-top: -10px;
    margin-bottom: 10px;
}