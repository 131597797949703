.reset-password-page {
    background-color: #08082F;
    /* padding: 0px 5px 0px 10px; */
    max-width: 400px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.resetButton {
    margin-top: 20px;
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}