.landing-page-link {
  font-family: brandFont;
  color: #FFFFFF;
}

#main_brand {
  font-family: brandFont, sans-serif;
  font-size: 70px;
  font-weight: 70;
  color: #FFFFFF;
}


#main_psa {
  color: #FFFFFF;
  font-family: brandFont, sans-serif;
  font-size: 36px;
  font-weight: 70;
}


#section_head {
  padding-top: 170px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-height: 50vh;
  background-color: #08082F;
}

footer {
  margin: 20px;
  /* padding-top: 250px; */
  /* padding-left: calc(100vw * 0.166666667); */
  background-color: #08082F;
  /* min-height: 70vh; */
  color: #FFFFFF;
}
footer table {
  width: 100%;
}

body {
  margin: 0;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  background-color: #08082F;
  color: #FFFFFF;
  display: flex;
  font-family: brandFont;
  
  font-weight: 75;
}




#section_describe {
  padding-top: 250px;
  background-color: #08082F;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: min(50px, 5%);
  padding-right: min(50px, 5%);
  align-items: center;
}


a {
  text-decoration: none;
}




#typical_text {
  max-width: 640px;
  word-wrap: break-word;
  text-align: left;
  line-height: 25px;
  /* 4px +12px + 4px */
}


.page-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 20px 20px;
  z-index: 1;
  background-color: #08082F;
}


.page-header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.button_header {
  border: none;
  color: white;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: brandFont;
}


.button_cta {
  border: none;
  color: white;
  padding: 15px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: brandFont;
}


.button {
  background-color: #ff33cc;
  border-radius: 4px;
}


.button_header:hover {
  background-color: #cc0099
}


.button_cta:hover {
  background-color: #cc0099
}


.page-header.is-sticky {
  position: fixed;
  padding: 8px 0;
  padding: 20px 20px;
  background-color: #08082F;
  animation: slideDown 0.35s ease-out;
}

.page-header.is-sticky img {
  max-width: 80%;
}

.page-header.is-sticky button {
  border: none;
  color: white;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-family: brandFont;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}








/* .landing-page-container {
  background: #08082F;
  height: 400vh;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.signup-link {
  color: #FFFFFF;
  margin-left: 10px;
  font-size: 20px;
  font-family: "brandFont";
}

.login-link {
  color: #FFFFFF;
  margin-right: 10px;
  font-size: 20px;
  font-family: "brandFont";
}

.centered-text {
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 120px;
  font-family: "brandFont";
}

.cura-description {
  margin-top: 20vh;
  padding: 20px;
  text-align: center;
  font-family: "brandFont";
  color: #FFFFFF;
  padding: 20px;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word
}

.cura-description h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.cura-description div {
  max-width: 640px;
    word-wrap: break-word;
    text-align: left;
    line-height: 25px;
}



.sign-up-section {
  margin-top: 20vh;
  padding: 20px;
  text-align: center;
  font-family: "brandFont";
  color: #FFFFFF;
  padding: 20px;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word
} */
