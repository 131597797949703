/* ConfirmationPopup.css */

.confirmation-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .confirmation-popup {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .confirmation-popup p {
    font-size: 16px;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .confirmation-popup-buttons {
    display: flex;
    justify-content: space-evenly;
  }
  
  .confirmation-popup-buttons .confirm-button,
  .confirmation-popup-buttons .cancel-button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirmation-popup-buttons .confirm-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .confirmation-popup-buttons .confirm-button:hover {
    background-color: #218838;
  }
  
  .confirmation-popup-buttons .cancel-button {
    background-color: #5bc0de;
    color: #fff;
  }
  
  .confirmation-popup-buttons .cancel-button:hover {
    background-color: #29a0c4;
  }
  