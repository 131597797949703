.create-note-container {
    background-color: #08082F;
    /* color: white; */
    padding: 20px;
    max-width: 400px;
    margin: auto;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh; */
}