.recording-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 400px;
    background-color: #08082F;
    /* background-color: #e74c3c; */
    /* Adjust the background color as needed */
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.2);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.recording-icon {
    cursor: pointer;
    margin-right: 10px;
    font-size: 24px;
}

.recording-text {
    cursor: pointer;
    margin: 0;
}