.processing {
  background-color: #FFA500;
  color: #08082F; /* Text color */
}

.processed {
  background-color: #08082F;
  color: #FFFFFF; 
}




.note-list-container {
    display: flex;
    max-width: 400px;
    flex-direction: column;
    align-items: center;
    /* margin: 20px; */
    background-color: #08082F;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }




/* Notes List Container */
.notes-list {
  width: 100%;
  max-width: 400px; /* Adjust as needed for your design */
  margin: 0 auto;
  padding: 16px;
  background-color: #08082F;
  color: white;
  border-radius: 8px;
}

/* Notes Header */
.notes-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

/* List of Notes */
.notes {
  list-style: none;
  padding: 0;
}

/* Individual Note Item */
.note {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
  color: #FFFFFF;
  cursor: pointer;
}

/* Row 1: Client Name and Date */
.row1 {
  display: flex;
  justify-content: space-between;
}

.client-name {
  font-weight: bold;
}

.date {
  font-style: italic;
}

/* Row 2: Summary */
.row2 {
  margin-top: 16px;
  line-height: 1.4; /* Adjust line spacing as needed */
  max-height: 5.6em; /* Limit summary to 4 lines */
  overflow: hidden;
}

/* Add three dots (...) for truncated text */
.row2::after {
  content: '...';
  display: inline;
  margin-left: 2px; /* Add spacing between text and dots */
}