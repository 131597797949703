.forgot-password-container {
    background-color: #08082F;
    color: white;
    padding: 20px;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh; 
}

.forgot-password-container .logo {
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.forgot-password-container a {
    color: #FFFFFF; 
}

input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.forgot-password-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.forgot-password-button:hover {
    background-color: #0073e6;
}
