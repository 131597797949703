.subscription-container {
    margin: 23px 11px 0px 14px;
    background-color: #08082F;
    color: white;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

subscription-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.options-container {
    margin-bottom: 20px;
}

.option {
    background-color: #004080;
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.option label {
    cursor: pointer;
}

.option input[type="radio"] {
    margin-right: 10px;
}

.subscribe-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.subscribe-btn:hover {
    background-color: #0073e6;
}

.option-sessions-info {
    margin-top: 10px;
}

.loader-height .loader-container{
    height: 30vh;
}
