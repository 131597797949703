.menu-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.menu-container {
    background-color: #08082F;
    padding: 20px;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.menu {
    width: 250px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    transition: background-color 0.3s;
}


.menu-item__icon {
    margin-right: 10px;
    font-size: 20px;
    /* Adjust the size of the icons */
}


.menu-item__label {
    font-weight: 900;
    color: #08082F;
    flex-grow: 1;
    font-size: 16px;
}

.menu-item--active {
    background-color: #f0f0f0;
    font-weight: bold;
}

.menu-logo {
    font-family: "brandFont";
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 25px;
    cursor: pointer;
}