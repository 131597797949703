/* .client-profiles-container {
  margin: 0px 20px 7px 20px;
} */

.client-header {
  padding-left: 15px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.client-profiles-container {
  max-width: 400px;
  background-color: #08082F;
  margin: auto;
  padding: 0px 20px 0px 20px;
}

/* .client-profiles {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
}

.client-circle {
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.all-clients-header {
  text-align: center;
}
.client-profiles {
  background-color: #08082F;
  overflow: auto;
  white-space: nowrap;
  margin-top: 13px;
  margin-bottom: 10px;
}
.client-circle {
  margin: 6px 0px 0px 8px; 
  font-size: 500;
  display: inline-block !important;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  cursor: pointer;
}
.client-circle:hover{
  background-color: #08082f;
  border: 2px solid #167af6;
}
#active {
  background-color: #08082f;
  border: 2px solid #167af6;
}
  
  .client-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .view-all-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .view-all-button:hover {
    background-color: #0056b3;
  }
  
  .button-container-client {
    display: flex;
    flex-direction: column;
    align-items: right;
  }
  .button-container-client-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    background-color: #ff33cc;
  }
  
  .add-client-button {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 30px !important;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
  }
  
  .add-client-button:hover {
    background-color: #218838;
  }
  
  .new-client-input {
    text-align: center;
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    /* background-color: white; */
    padding: 20px;
    border-radius: 8px;
  }
  
  .popup-inner {
    text-align: center;
  }
  
  /* Optional: You can further style the popup as needed */
  