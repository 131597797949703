.privacy-container {
    background-color: #08082F;
    color: white;
    padding: 20px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
}

.privacy-container h1 {
    margin-bottom: 20px;
    text-align: center;
}

.privacy-container h2 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-container p, .privacy-container ul {
    margin-bottom: 10px;
}

section {
    margin-bottom: 20px;
}

/* Responsive design for mobile views */
@media (max-width: 768px) {
    .privacy-container {
        padding: 10px;
        font-size: 14px;
    }

    .privacy-container h1 {
        font-size: 24px;
    }

    .privacy-container h2 {
        font-size: 18px;
    }
}

.btn-privacy-policy {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}