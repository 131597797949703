.client-info-container {
    background-color: #08082F; /* Dark blue background */
    color: white;
    padding: 10px;
    width: 350px;
}

.btn-class {
    margin-top: 20px;
}
.btn-client-info {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
