.summary-container {
    /* font-family: 'Arial, sans-serif';
    color: #FFFFFF;
    background-color: #08082F;
    padding: 20px;
    position: relative; */
    background-color: #08082F;
    padding: 0px 20px 0px 20px;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
  }
  
  .summary-date {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .summary-heading {
    text-align: center;
  }
  
  .summary-copy-button {
    cursor: pointer;
    background-color: #333;
    color: #FFF;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  
  .copy-icon {
    cursor: pointer;
  }
  .btn-class {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }