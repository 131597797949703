.login-container {
    background-color: #08082F; /* Dark blue background */
    color: white;
    padding: 20px;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

.login-container .logo {
    color: #FFFFFF; /* Lighter blue for the logo */
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}

input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.error-message {
    color: #ff6666; /* Error message color */
    margin-bottom: 10px;
}

.login-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.login-btn:hover {
    background-color: #0073e6;
}

a:hover {
    text-decoration: underline;
}
