@font-face {
    font-family: "brandFont";
    src: local("questrial-regular"),
      url("../../font/questrial-regular.ttf") format("truetype");
  }

.cura-font-bold {
    font-family: "brandFont";
    font-weight: 75;
}
