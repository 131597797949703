.note-previewer-container {
    margin: 10px;
    /* background-color: #08082F;
    color: white;
    padding: 20px;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh; */
}

.note-btn {
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #0059b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.loader-height .loader-container{
    height: 30vh;
}
